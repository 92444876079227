import { ImageWorker } from "./image-worker.ts";
import { EncodeResult } from "./worker.ts";
import { ControlType, OptionStateMap, OptionType } from "../form/index.ts";
import * as JXL from "../codecs/jxl.ts";
import * as WebP from "../codecs/webp.ts";
import * as HEIC from "../codecs/heic.ts";
import * as AVIF from "../codecs/avif.ts";
import * as WebP2 from "../codecs/webp2.ts";
import * as QOI from "../codecs/qoi.ts";
import * as PngQuant from "../codecs/pngquant.ts";
import * as MozJPEG from "../codecs/mozjpeg.ts";

export interface ImageEncoder {
	name: string;
	extension: string;
	mimeType: string;

	templates: OptionType[];
	defaultOptions: Record<string, any>;

	/**
	 * Convert options generated by form and invoke the encode worker.
	 *
	 * @param options Form options.
	 * @param worker The encode worker, input image is already set.
	 */
	encode(options: any, worker: ImageWorker): Promise<EncodeResult>;
}

export const ENCODERS: ImageEncoder[] = [MozJPEG, PngQuant,  QOI, WebP, HEIC, AVIF,JXL, WebP2];

export const ENCODER_MAP = Object.fromEntries(ENCODERS.map(e => [e.name, e]));

const names = ENCODERS.map(e => e.name);

export function getEncoderNames(object: Record<string, unknown>) {
	return names.filter(name => name in object);
}

export function buildProfiles(encoder: ImageEncoder, state: OptionStateMap) {
	const { defaultOptions, templates } = encoder;

	const variables: Record<string, unknown[]> = {};
	const constants = { ...defaultOptions };
	const controls: ControlType[] = [];
	const weights: number[] = [];

	let size = 1;

	for (const template of templates) {
		const { isVariable, value, range } = state[template.id];

		if (!isVariable) {
			template.populate(value, constants);
		} else {
			const control = template.createControl(range);
			const values = control.createState();
			size *= values.length;
			controls.push(control);
			variables[template.id] = values;
		}
	}

	let weight = 1;
	for (let i = controls.length - 1; i >= 0; i--) {
		weights.push(weight);
		weight *= variables[controls[i].id].length;
	}

	return { size, variables, constants, controls, weights };
}
