const translations = {
	en: {
		ICAnalyzerIntro: "Online Image Compression & Analysis Tool",
		SourceCode: "Source Code",
		Back: "Back",
		SelectFile: "Select a file",
		ShowMetrics: "Show metrics",
		DownloadOutput: "Download output image",
		LockYAxis: "Lock Y axis",
		DownloadSVG: "Download SVG image",
		DownloadFullAxis: "Download with full Axis",
		MeasureOptions: "Measure Options",
		Remove: "Remove",
		AddAtLeast2Images: "Add at least two images",
		FirstIsTheOriginal: "The first will be the original",
		DndToReorder: "Use drag & drop to change the order",
		AnalysisConfig: "Analysis config",
		EncodeTimeSecond: "Encode Time (s)",
		Codec: "Codec",
		Preview: "Preview",
		FileName: "File name",
		FileType: "File type",
		Resolution: "Resolution",
		RawSize: "Raw size",
		FileSizeAndRatio: "File size (Ratio %)",
		SplitView: "Split",
		InputView: "Input",
		OutputView: "Output",
		DiffView: "Diff",
		HeatMapView: "HeatMap",
		RequireButteraugli: "Require enable butteraugli",
		PickColor: "Pick color",
		Brightness: "Brightness",
		ThreadCount: "Thread count (more threads require more RAM)",
		MeasureEncodeTime: "Encode time (Not very accurate)",
		MeasurePSNR: "Peak signal-to-noise ratio (PSNR)",
		MeasureSSIM: "Structural similarity index measure (SSIM)",
		Encoding: "Encoding",
		ElapsedTime: "Elapsed time",
		Cancel: "Cancel",
		Error: "Error",
		OrTryOneOfThese: "Or try one of these",
		DropAFile: "Drag & drop",
		OrSelectFile: "Or click to select a file",
		OrSelectFiles: "Or click to select files",
		SelectDirectory: "Select directory",
		ZoomScale: "Zoom scale",
		ResetPinchZoom: "Reset pinch zoom",
		Information: "Information",
		Encoders: "Encoders",
		Analysis: "Analysis",
		Start: "Start",
		NoOptions: "(No Options)",
	},
	zh: {
		ICAnalyzerIntro: "在线的图片转码与分析工具",
		SourceCode: "开源仓库",
		Back: "返回",
		SelectFile: "选择文件",
		ShowMetrics: "显示指标",
		DownloadOutput: "下载当前的输出图片",
		LockYAxis: "锁定 Y 轴",
		DownloadSVG: "保存为 SVG 图",
		DownloadFullAxis: "保存并显示所有 Y 轴",
		MeasureOptions: "测量选项",
		Remove: "移除",
		AddAtLeast2Images: "选择至少两张图片",
		FirstIsTheOriginal: "第一个将被视为原图",
		DndToReorder: "拖拽可以调整顺序",
		AnalysisConfig: "分析设置",
		EncodeTimeSecond: "转换用时 (秒)",
		Codec: "编码",
		Preview: "预览",
		FileName: "文件名",
		FileType: "文件类型",
		Resolution: "分辨率",
		RawSize: "原始大小",
		FileSizeAndRatio: "大小（压缩率）",
		SplitView: "分割",
		InputView: "原图",
		OutputView: "输出",
		DiffView: "差分",
		HeatMapView: "HeatMap",
		RequireButteraugli: "需要启用 butteraugli",
		PickColor: "取色",
		Brightness: "亮度",
		ThreadCount: "线程数（增加将占用更多内存）",
		MeasureEncodeTime: "转换时间（不是太准）",
		MeasurePSNR: "峰值信噪比 (PSNR)",
		MeasureSSIM: "结构相似度 (SSIM)",
		Encoding: "转码中",
		ElapsedTime: "已经过时间",
		Cancel: "取消",
		Error: "错误",
		OrTryOneOfThese: "或者尝试以下的",
		DropAFile: "拖放文件",
		OrSelectFile: "或者点击选择",
		OrSelectFiles: "或者点击选择",
		SelectDirectory: "选择文件夹",
		ZoomScale: "缩放率",
		ResetPinchZoom: "重置位置和缩放",
		Information: "信息",
		Encoders: "转码器",
		Analysis: "分析",
		Start: "开始",
		NoOptions: "（没有选项）",
	},
};

type LangCode = keyof typeof translations;
type Keys = keyof typeof translations.en;

const userLang = (localStorage.getItem("lang") ?? navigator.language.split("-", 2)[0]) as LangCode;
const resources = translations[userLang] ?? translations.en;

export function setLocale(code: string) {
	localStorage.setItem("lang", code);
}

export default function i18n(key: Keys) {
	return resources[key];
}
